import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { ReferencesBlock, PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, ComparisonTable, EndpointDisplay } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import MSI_CRC from '../../components/StudyDesign/__study-design/msi-crc'
import { jobCodes } from '../../util/data/jobCodes';
import { NotationBlock, Heading } from '../../util/typography';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-177 - Clinical Trial Results | HCP`,
    keywords: `keynote 177, msi-h colorectal cancer clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-177 in patients with advanced MSI-H/dMMR colorectal cancer (CRC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr-colorectal-cancer/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠177","description":"Clinical Findings from KEYNOTE⁠-⁠177","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠177, a multicenter, randomized, open-label, active-controlled trial that enrolled 307 patients with previously untreated unresectable or metastatic MSI⁠-⁠H or dMMR CRC."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr-colorectal-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 23;
const jobCode = jobCodes[23].jobCode;

const pageReferences = [
    {
        label: "1.",
        text: "Shiu K, Andre T, Kim TW, et al. Pembrolizumab versus chemotherapy in microsatellite instability-high/mismatch repairdeficient metastatic colorectal cancer: 5-year follow-up of the randomized phase 3 KEYNOTE-177 study. Slide deck presented at: European Society for Medical Oncology; October 20, 2023; Madrid, Spain."
    }
]

const ChartTitle = styled(Heading.H4)``;

const Limitation = styled.div`
    margin-bottom: 20px;
    display: inline-block;
    strong {
        font-weight: 600;
    }
`;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'MSI&#8288;-&#8288;H/dMMR Cancers', url: '/efficacy/msi-h-dmmr/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'MSI/MMR Testing', url: '/biomarker-testing/msi-mmr/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            {
                eyebrow: 'Resources',
                links: [
                    {
                        text: 'Mechanism of Action',
                        url: '/resources/mechanism-of-action/',
                    },
                    {
                        text: 'Hypothetical Patient Profile',
                        url: '/static/pdf/US-OVC-00878_ADT_HCP_Grace_Patient_Profile_PDF_MSI-H_CRC_L08_Interactive_Secure.pdf',
                        target: '_blank'
                    }
                ]
            },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;177' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;177' }
]

const key177SecondaryCalloutFootnotes = [
    {
        label: "a.",
        text: "mFOLFOX6 (oxaliplatin, leucovorin, and [fluorouracil] FU) or mFOLFOX6 in combination with either bevacizumab or cetuximab or FOLFIRI (irinotecan, leucovorin, and FU) or FOLFIRI in combination with either bevacizumab or cetuximab."
    },
    {
        label: "b.",
        text: "Based on Cox regression model."
    },
    {
        label: "c.",
        text: "Two-sided <i>P</i> value based on log-rank test (compared to a significance level of 0.0234)."
    },
];

const key177KMcurveFootnotes = [
    {
        label: "d.",
        text: "As assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ."
    },
    {
        label: "e.",
        text: "mFOLFOX6 (oxaliplatin, leucovorin, and [fluorouracil] FU) or mFOLFOX6 in combination with either bevacizumab or cetuximab or FOLFIRI (irinotecan, leucovorin, and FU) or FOLFIRI in combination with either bevacizumab or cetuximab."
    },
    {
        label: "f.",
        text: "Based on Cox regression model."
    },
    {
        label: "g.",
        text: "Two-sided P value based on log-rank test (compared to a significance level of 0.0234)."
    },
];

const Year5KMCurveFootnotes = [
    {
        label: "m.",
        text: "As assessed by BICR according to RECIST v1.1."
    },
];

const PFSComparisonFootnotes = [
    {
        label: 'h.',
        text: 'Based on Cox regression model.'
    },
    {
        label: 'i.',
        text: 'Two-sided <i>P</i> value based on log-rank test (compared to a significance level of 0.0234).'
    },
]

const OSrateFootnotes = [
    {
        label: 'j.',
        text: 'Final OS analysis.'
    },
    {
        label: 'k.',
        text: 'Based on Cox regression model.'
    },
    {
        label: 'l.',
        text: 'Two-sided <i>P</i>-value based on log-rank test (compared to a significance level of 0.0492).'
    },
]

const OREndpointFootnotes = [
    {
        label: 'n.',
        text: 'Based on confirmed response by BICR review.'
    },
]

const DORtableFootnotes = [
    {
        label: 'o.',
        text: 'Based on confirmed response by BICR review.'
    },
    {
        label: 'p.',
        text: 'Based on n=67 patients with a response in the KEYTRUDA arm and =51 patients with a response in the chemotherapy arm.'
    },
    {
        label: 'q.',
        text: '+Denotes ongoing response.'
    },
    {
        label: 'r.',
        text: '+Indicates no PD by the time of last assessment.'
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">The First Anti–PD&#8288;-&#8288;1 Approved for First-line Treatment of Advanced MSI&#8288;-&#8288;H/dMMR CRC</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <SecondaryCallout 
                                title="40% reduction in the risk of disease progression or death with KEYTRUDA vs chemotherapy<sup>a</sup>" 
                                text="HR<sup>b</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>c</sup>=0.0004" 
                                alt="40% Reduction in the Risk of Disease Progression or Death With KEYTRUDA® (pembrolizumab) vs Chemotherapy"
                                footnotes={key177SecondaryCalloutFootnotes}
                            />
                            <KMCurve
                                title="Kaplan-Meier Estimates of PFS<sup>d</sup> With KEYTRUDA vs Chemotherapy<sup>e</sup>"
                                hr="HR<sup>f</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>g</sup>=0.0004"
                                footnotes={key177KMcurveFootnotes}
                                definitions="BICR = blinded independent central review; CI = confidence interval; HR = hazard ratio; PD&#8288;-&#8288;1 = programmed death receptor-1; PFS = progression-free survival; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1."
                                image={() => <StaticImage src="../../assets/crc-177-km-v-2.png"
                                    placeholder="blurred"
                                    alt="Kaplan-Meier Estimates of PFS in Patients With Advanced MSI-H/dMMR Colorectal Cancer (CRC) With KEYTRUDA® (pembrolizumab) vs Chemotherapy In KEYNOTE-177"
                                />} />

                            <ComparisonTable 
                                title="Progression-Free Survival (PFS): Double the median PFS with KEYTRUDA" 
                                footnotes={PFSComparisonFootnotes}
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=153)',
                                            },
                                            {
                                                legendTitle: 'Chemotherapy (n=154)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>h</sup>=0.60; 95% CI, 0.45–0.80; <i>P</i><sup>i</sup>=0.0004',
                                                colspan: 2,
                                                centerText: true,
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events observed',
                                                dataPoint: '54%',
                                                dataSubPoint: '(n=82/153)'
                                            },
                                            {
                                                label: 'Events observed',
                                                dataPoint: '73%',
                                                dataSubPoint: '(n=113/154)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '16.5 months',
                                                dataSubPoint: '(95% CI, 5.4–32.4)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '8.2 months',
                                                dataSubPoint: '(95% CI, 6.1–10.2)'
                                            },
                                        ]
                                
                                    ]
                                }
                            />

                            {/* OS Data */}
                            <ComparisonTable 
                                title="Overall Survival (OS)<sup>j</sup>" 
                                limitation="There was no statistically significant difference between KEYTRUDA and chemotherapy in the final OS analysis. Sixty percent of the patients who had been randomized to receive chemotherapy had crossed over to receive subsequent anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 therapies including KEYTRUDA. Median follow-up time at the final analysis was 38.1 months (range: 0.2 to 58.7 months)."
                                footnotes={OSrateFootnotes} 
                                definitions="PD-L1 = program death ligand 1."
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=153)',
                                            },
                                            {
                                                legendTitle: 'Chemotherapy (n=154)',
                                            },

                                        ],
                                        [
                                            {
                                                colspan: 2,
                                                centerText: true,
                                                hrCiP: 'HR<sup>k</sup>=0.74; 95% CI, 0.53–1.03; <i>P</i><sup>l</sup>=0.0718'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: 'Not reached (95% CI 49.2, Not reached)',
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '36.7 months (95% CI 27.6, Not reached)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '41%',
                                                dataSubPoint: '(n=62/153)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '51%',
                                                dataSubPoint: '(n=78/154)'
                                            },
                                        ],
                                    ]
                                } 
                            />

                            <KMCurve
                                title="PFS: 5-Year Follow-Up<sup>1</sup>"
                                limitation="<b>LIMITATIONS:</b> The 5-year follow-up of KN-⁠177 was a post hoc, exploratory analysis. No formal statistical testing was planned for this analysis and, therefore, no statistical conclusions can be drawn."
                                footnotes={Year5KMCurveFootnotes}
                                hr="Kaplan-Meier Estimates of PFS<sup>m</sup> With KEYTRUDA vs Chemotherapy"
                                image={() => <StaticImage src="../../assets/P00106633_KN-177_PFS_KM_SOURCE_Shiu_ESMO_2023_p8_RGB_r9.jpg"
                                    placeholder="blurred"
                                    alt="Kaplan-Meier Estimates of PFS With KEYTRUDA® (pembrolizumab) vs Chemotherapy in KEYNOTE-177"
                                />} 
                            />


                            <ComparisonTable 
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=153)',
                                            },
                                            {
                                                legendTitle: 'Chemotherapy (n=154)',
                                            },

                                        ],
                                        [
                                            {
                                                label: 'Events observed',
                                                dataPoint: '61.4%',
                                                dataSubPoint: '(n=94)'
                                            },
                                            {
                                                label: 'Events observed',
                                                dataPoint: '79.2%',
                                                dataSubPoint: '(n=122)'
                                            },
                                        ]
                                    ]
                                } 
                            />

                            <Limitation forwardedAs="div" dangerouslySetInnerHTML={{__html:
                                'Median time from randomization to data cutoff was 73.3 months (6.1 years; range: 64.9–89.2 months)'
                            }}></Limitation>

                            <PageSubSection>
                                <ChartTitle as='H3' dangerouslySetInnerHTML={{ __html: "Objective Response Rate (ORR)<sup>n</sup>" }}/>
                                <EndpointDisplay 
                                    footnotes={OREndpointFootnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '44%',
                                                n: '67/153',
                                                ci: '95% CI, 35.8–52.0',
                                                completeResponseRate: '11%',
                                                partialResponseRate: '33%',
                                            },
                                            {
                                                dataType: 'ORR',
                                                label: 'Chemotherapy',
                                                rate: '33%',
                                                n: '51/154',
                                                ci: '95% CI, 25.8–41.1',
                                                completeResponseRate: '4%',
                                                partialResponseRate: '29%',
                                            }
                                        ]
                                    }
                                />
                        </PageSubSection>
                        <PageSubSection>
                                <ChartTitle as='H3' dangerouslySetInnerHTML={{ __html: "Duration of Response (DOR)<sup>o,p</sup>" }}/>
                                <ComparisonTable 
                                    title="The median follow-up time at the final analysis was 38.1 months (range 0.2 to 58.7 months)"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA',
                                                },
                                                {
                                                    legendTitle: 'Chemotherapy',
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median DOR<sup>o</sup>',
                                                    dataPoint: 'Not reached',
                                                    dataSubPoint: '(range: 2.3+<sup>q</sup> to 41.4+<sup>q</sup> months)'
                                                },
                                                {
                                                    label: 'Median DOR<sup>o</sup>',
                                                    dataPoint: '10.6 months',
                                                    dataSubPoint: '(range: 2.8 to 37.5+<sup>q</sup> months)'
                                                },
                                            ]

                                        ]
                                    } 
                                />

                                <ComparisonTable 
                                    title="DOR: 5-Year Follow-up<sup>1</sup>" 
                                    limitation="<b>LIMITATIONS:</b> The 5-year follow-up of KN-⁠177 was a post hoc, exploratory analysis. No formal statistical testing was planned for this analysis and, therefore, no statistical conclusions can be drawn." 
                                    definitions="<p>PD = progressive disease.</p>"
                                    footnotes={DORtableFootnotes} 
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA',
                                                },
                                                {
                                                    legendTitle: 'Chemotherapy',
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '75.4 months',
                                                    dataSubPoint: '(range: 2.3+<sup>r</sup> to 80.1+<sup>r</sup>)'
                                                },
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '10.6 months',
                                                    dataSubPoint: '(range: 2.8 to 71.5+<sup>r</sup> months)'
                                                },
                                            ]

                                        ]
                                    } 
                                />
                            
                            </PageSubSection>
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <MSI_CRC />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>

                </TemplateColumn>


            </div>
        </DefaultLayout>
    );
};

export default Page;
